<template>
  <footer class="footer pb-0">
    <div class="background-overlay">
      <img
        :src="require('@/assets/images/logo/afp-logo.png')"
        alt="Background Image"
        class="footer__background-img"
      />
    </div>
    <b-row>
      <b-col lg="4" sm="12">
        <div class="footer__addr">
          <Contact />
        </div>
      </b-col>
      <b-col lg="8" sm="12" class="mt-2">
        <b-row>
          <b-col md="4" sm="12">
            <ul class="">
              <li class="nav__item">
                <h2 class="nav__title">External links</h2>

                <ul class="nav__ul">
                  <li>
                    <img
                      :src="require('@/assets/images/logo/ispr.png')"
                      alt="Logo"
                      class="footer-img"
                    />
                    <a
                      class="footer-link"
                      href="https://www.ispr.gov.pk/"
                      target="_blank"
                      >Inter Services Public Relations</a
                    >
                  </li>

                  <li>
                    <img
                      :src="require('@/assets/images/logo/PMA.png')"
                      alt="Logo"
                      class="footer-img"
                    />
                    <a
                      class="footer-link"
                      href="https://www.pakistanarmy.gov.pk/Pakistan-Military-Academy"
                      target="_blank"
                      >Pakistan Military Academy</a
                    >
                  </li>

                  <li>
                    <img
                      :src="require('@/assets/images/logo/ssc.png')"
                      alt="Logo"
                      class="footer-img"
                    />
                    <a
                      class="footer-link"
                      href="https://cscquetta.gov.pk/"
                      target="_blank"
                      >Command & Staff College</a
                    >
                  </li>
                  <li>
                    <img
                      :src="require('@/assets/images/logo/NDU.png')"
                      alt="Logo"
                      class="footer-img"
                    />
                    <a
                      class="footer-link"
                      href="https://ndu.edu.pk/"
                      target="_blank"
                      >National Defence University</a
                    >
                  </li>
                  <!-- <li>
                    <img
                      :src="require('@/assets/images/logo/logo-x.png')"
                      alt="Logo"
                      class="footer-img"
                    />
                    <a href="#">Army</a>
                  </li> -->
                  <li>
                    <img
                      :src="require('@/assets/images/logo/logo-x.png')"
                      alt="Logo"
                      class="footer-img"
                    />
                    <a
                      class="footer-link"
                      href="https://joinpakarmy.gov.pk"
                      target="_blank"
                      >Join Pakistan Army</a
                    >
                  </li>
                  <li>
                    <img
                      :src="require('@/assets/images/logo/CNE.png')"
                      alt="Logo"
                      class="footer-img"
                    />
                    <a
                      class="footer-link"
                      href="https://milhosp.gov.pk/"
                      target="_blank"
                      >Military Hospitals</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </b-col>
          <b-col md="4" sm="12">
            <ul>
              <li class="nav__item">
                <h2 class="nav__title">Policies</h2>

                <ul class="nav__ul">
                  <li>
                    <a class="footer-link" @click="hajjUmrah">Hajj Umrah</a>
                  </li>

                  <li>
                    <a class="footer-link" @click="FCPS"
                      >FCPS MCPS Trg Civ Trainees</a
                    >
                  </li>

                  <li>
                    <a class="footer-link" @click="MiscPoliciesCORO"
                      >Misc Policies CORO</a
                    >
                  </li>
                  <!-- <li>
                    <a href="#">HSG OTE/ Askari</a>
                  </li> -->
                  <!-- <li>
                    <a href="#">Defence Housing Authority</a>
                  </li>
                  <li>
                    <a href="#">Spec Instructions</a>
                  </li> -->
                </ul>
              </li>
            </ul>
          </b-col>
          <b-col md="4" sm="12">
            <ul class="">
              <li class="nav__item">
                <h2 class="nav__title">Quick Links</h2>

                <ul class="nav__ul">
                  <li>
                    <b-link class="footer-link" to="/home">Home</b-link>
                  </li>

                  <li>
                    <b-link class="footer-link" to="/home">Announcement</b-link>
                  </li>

                  <li>
                    <b-link :to="{ name: 'ContactUs' }">Contact Us</b-link>
                  </li>

                  <li>
                    <router-link to="/login">Sign In</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </b-col>

          <!-- <b-col md="2.4">
    <ul class="nav flex-column">
      <li class="nav-item">
        <h2 class="nav-title">Quick Links</h2>
        <b-dropdown v-if="isMobile" text="Menu">
          <b-dropdown-item v-for="link in links" :key="link.name" :to="link.to" :href="link.href">
            {{ link.name }}
          </b-dropdown-item>
        </b-dropdown>
        <ul v-else class="nav-ul">
          <li v-for="link in links" :key="link.name" class="nav-item">
            <router-link v-if="link.type === 'router'" class="nav-link" :to="link.to">{{ link.name }}</router-link>
            <a v-else class="nav-link" :href="link.href">{{ link.name }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </b-col> -->

          <!-- <b-col md="2.4">
            <div class="footer__addr">
              <div class="logo-container mt-0 mb-0 ml-3">
                <img
                  :src="require('@/assets/images/logo/afp-logo.png')"
                  alt="Logo"
                  class="footer__logo-img"
                />
                <h1
                  class="footer__logo m-2"
                  style="font-size: 2rem; font-weight: 600"
                >
                  {{ appName }}
                </h1>
              </div>
              <div class="ml-5 pt-2">
                <a href="#home" class="d-flex align-content-start">
                  <feather-icon icon="HomeIcon" size="16" class="mr-1" />Hilal
                  Rd, Rawalpindi, Punjab 46300
                </a>
                <br />
                <a
                  href="tel:051 111 130 786"
                  class="phone d-flex align-content-start"
                >
                  <feather-icon icon="PhoneIcon" size="16" class="mr-1" />051
                  111-130-786
                </a>
              </div>
            </div>
          </b-col> -->
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <hr />
        <!-- <h4 class="text-white text-center ">
          
          Developed and Maintained by Adjutant's General Branch
          <img
                      :src="require('@/assets/images/logo/logo-x.png')"
                      alt="Logo"
                      class="footer-img"
                    />
        </h4> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8" class="order-md-1">
        <b-link to="/home">
          <div class="logo-container mt-0 mb-0 ml-md-5">
            <img
              :src="require('@/assets/images/logo/afp-logo.png')"
              alt="Logo"
              class="footer__logo-img"
            />
            <h1
              class="footer__logo mt-1 ml-50 text-nowrap"
              style="font-size: 2rem; font-weight: 600"
            >
              {{ appName }}
            </h1>
          </div>
        </b-link>
      </b-col>

      <b-col md="4" class="order-md-2">
        <div
          class="ml-1 pt-2 d-md-flex flex-md-row-reverse justify-content-md-end text-wrap"
        >
          <a href="tel:051 111 130 786">
            <feather-icon icon="PhoneIcon" size="16" class="mr-1" />051
            111-130-786
          </a>
          <br />
          <div class="ml-md-3 mr-1 text-wrap">
            <a href="#home" class="d-flex justify-content-md-end">
              <feather-icon icon="HomeIcon" size="16" class="mr-1" />Hilal Rd,
              Rawalpindi, Punjab 46300
            </a>
          </div>
        </div>
      </b-col>
    </b-row>
  </footer>
</template>

<script>
import Contact from "@/components/newHome/Contact.vue";
import { $themeConfig } from "@themeConfig";
export default {
  name: "Footer",
  components: {
    Contact,
  },
  methods: {
    hajjUmrah() {
      window.open("/assets/policy_letters/policyHajjUmra.pdf", "_blank");
    },
    FCPS() {
      window.open(
        "/assets/policy_letters/FCPS&MCPSTrgofCivTrainees.pdf",
        "_blank"
      );
    },
    MiscPoliciesCORO() {
      window.open("/assets/policy_letters/MiscPoliciesCORO.pdf", "_blank");
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
};
</script>

<style scoped>
.footer {
  background-color: #aec2b6 !important;
  height: 100%;
  width: 100%;
  position: relative;
}

.background-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 90%;
  background-image: url("~@/assets/images/logo/afp-logo.png");
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.05;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__background-img {
  display: none;
}

.logo-container {
  display: flex;
  justify-content: start;
}
.centered_img {
  align-items: center;
  padding: 50px 30px;
  text-align: center;
}

.footer__logo-img {
  width: 60px;
  height: 60px;
}

.footer__logo {
  font-weight: 700;
  color: black;
  font-size: 1.5rem;
}

.nav__title {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
  color: black;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer-img {
  max-height: 30px;
  max-width: 30px;
  margin: 1px;
  margin-right: 10px;
}

.footer li {
  line-height: 2em;
  margin-bottom: 10px;
}

.footer a {
  text-decoration: none;
  color: black;
  font-weight: 500;
}
.nav__ul li {
  white-space: nowrap;
  margin-bottom: 8px;
}
/* .nav__item {
  padding: 10px 40px; 

}
@media (min-width: 576px) {
  .nav__item {
    padding: 10px 100px;
  }
} */
.footer-link {
  white-space: wrap;
  width: 100%;
}
</style>
