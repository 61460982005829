<template>
  <b-navbar
    toggle-class="navbar-expand-sm"
    style="z-index: 1000; background-color: white"
    class="d-lg-block d-none"
  >
    <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
    <b-collapse id="nav_collapse" is-nav>
      <b-nav class="mx-auto mt-25">
        <li class="nav-item">
          <a class="nav-link font-weight-bolder" href="#home">
            <feather-icon icon="HomeIcon" size="18" /> &nbsp;{{
              $t("navbar.home")
            }}</a
          >
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link scrollactive-item font-weight-bolder ml-1">
            <feather-icon icon="BookIcon" size="18" />
            {{ $t("navbar.PolicyLetters") }}</a
          >
        </li> -->
        <li class="nav-item mr-1" style="margin-top: -5px">
          <b-nav-item-dropdown
            toggle-class="d-flex align-items-center "
            class="dropdown-user"
            style="list-style-type: none"
            :right="true"
          >
            <template #button-content>
              <div class="user-nav"></div>
              <a
                class="nav-link scrollactive-item font-weight-bolder ml-1"
                style="margin-right: -10px !important"
              >
                <feather-icon icon="BookIcon" size="18" />
                {{ $t("navbar.PolicyLetters") }}</a
              >
            </template>

            <b-dropdown-item
              @click="hajjUmrah"
              link-class="d-flex align-items-center"
            >
              <span>{{ $t("navbar.Hajj_Umrah") }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="FCPS"
              link-class="d-flex align-items-center"
            >
              <span>{{ $t("navbar.FCPS") }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="MiscPoliciesCORO"
              link-class="d-flex align-items-center"
            >
              <span>{{ $t("navbar.Misc") }}</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item link-class="d-flex align-items-center">
              <span>DHAs</span>
            </b-dropdown-item>

            <b-dropdown-item link-class="d-flex align-items-center">
              <span>Spec Instructions</span>
            </b-dropdown-item> -->
          </b-nav-item-dropdown>
        </li>
        <li
          class="nav-item ml-lg-1 mt-1 mt-lg-0"
          v-if="hasRole('su') || hasRole('op') || hasRole('op_admin')"
        >
          <b-link
            class="nav-link font-weight-bolder"
            :to="{ name: 'NewDashboard' }"
          >
            <feather-icon icon="UserIcon" size="18" /> &nbsp;
            {{ $t("navbar.admin") }}
          </b-link>
        </li>
        <li
          class="nav-item ml-lg-1 mt-1 mt-lg-0"
          v-if="getUser ? getUser.role_data.code_name.includes('bk__') : false"
        >
          <b-link
            class="nav-link font-weight-bolder"
            :to="{ name: 'Booking' }"
          >
            <feather-icon icon="UserIcon" size="18" /> &nbsp;
            {{ $t("navbar.admin") }}
          </b-link>
        </li>
        <li
          class="nav-item ml-lg-1 mt-1 mt-lg-0"
          v-if="hasRole('re-emp_noc_op') || hasRole('re-emp_noc_admin')"
        >
          <b-link
            class="nav-link font-weight-bolder"
            :to="{ name: 'JobNoc' }"
          >
            <feather-icon icon="UserIcon" size="18" /> &nbsp;
            {{ $t("navbar.admin") }}
          </b-link>
        </li>
        <li class="nav-item ml-lg-1 mt-1 mt-lg-0" v-if="hasRole('fb' || 'fba')">
          <b-link
            class="nav-link font-weight-bolder"
            :to="{ name: 'FeedbackLanding' }"
          >
            <feather-icon icon="UserIcon" size="18" /> &nbsp;
            {{ $t("navbar.dash") }}
          </b-link>
        </li>

        <!-- <li class="nav-item ml-lg-1 mt-1 mt-lg-0">
          <a
            class="nav-link scrollactive-item font-weight-bolder"
            href="#announcements"
          >
            <feather-icon icon="PhoneCallIcon" size="18" /> &nbsp;
            {{ $t("navbar.announcements") }}
          </a>
        </li> -->
        <li class="nav-item ml-lg-1 mt-1 mt-lg-0">
          <b-link
            class="phone nav-link scrollactive-item font-weight-bolder"
            :to="{ name: 'ContactUs' }"
          >
            <feather-icon icon="PhoneCallIcon" size="18" /> &nbsp;
            {{ $t("navbar.contactUs") }}
          </b-link>
        </li>

        <div class="">
          <b-nav-item-dropdown
            v-if="getUser"
            toggle-class="d-flex align-items-center dropdown-user-link"
            class="dropdown-user"
            style="list-style-type: none"
          >
            <template #button-content>
              <div class="d-sm-flex d-none user-nav"></div>
              <b-avatar
                size="40"
                variant="light-primary"
                badge
                :src="getUser ? getUser.profile_image : ''"
                class="badge-minimal"
                badge-variant="success"
              />
            </template>

            <b-dropdown-item
              v-if="hasRole('su') || hasRole('op') || hasRole('op_admin')"
              :to="{ name: 'Profile' }"
              link-class="d-flex align-items-center"
            >
              <feather-icon size="16" icon="UserIcon" class="mr-50" />
              <span>{{ $t("navbar.Profile") }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              :to="{ name: 'Profile' }"
              link-class="d-flex align-items-center"
            >
              <feather-icon size="16" icon="UserIcon" class="mr-50" />
              <span>Profile</span>
            </b-dropdown-item>

            <b-dropdown-divider />

            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="logoutButtonClick"
            >
              <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
              <span>{{ $t("navbar.Logout") }}</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <span v-else class="nav-item ml-lg-1 mt-2 mt-lg-0">
            <b-link class="btn btn-primary" :to="{ name: 'Login' }">
              <feather-icon icon="LogInIcon" size="20" />
              {{ $t("navbar.signIn") }}
            </b-link>
          </span>
        </div>
      </b-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import store from "@/store";

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      appName: "",
      appLogoImage: "",
      activeMainCategoryIndex: 0,
      lastX: 0,
      showMeagaMenu: false,
      lastY: 0,
      direction: "",
      activeMainCategoryTitle: null,
      throttle: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
        umrah: "~@/assets/policy_letters/policyHajjUmra.pdf",
      },
      // bookingRoles: [
      //   'bk__agm',
      //   'bk__mess_sec',
      //   'bk__fmn_adm'
      // ]      
    };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
    // this.activeMainCategoryTitle =
    //   this.categoriesList[this.activeMainCategoryIndex].Name;
  },
  methods: {
    ...mapActions({ logout: "appData/logout" }),
    async logoutButtonClick() {
      try {
        let user = this.getUser
        const res = await this.logout();
        if (res.status === 204) {
          store.commit("appConfig/UPDATE_SKIN", "light");
          if(user.role_data.code_name.includes('bk__')){
            this.$router.push({ name: "BookingLogin" })
          }
          else{
            this.$router.push({ name: "Login" });
          }
        }
      } catch (error) {
        error;
        this.displayError(error);
      }
    },
    hajjUmrah() {
      const link = document.createElement("a");
      link.href = "/assets/policy_letters/policyHajjUmra.pdf";
      link.download = "policyHajjUmra.pdf";
      link.click();
    },
    FCPS() {
      const link = document.createElement("a");
      link.href = "/assets/policy_letters/FCPS&MCPSTrgofCivTrainees.pdf";
      link.download = "FCPS&MCPSTrgofCivTrainees.pdf";
      link.click();
    },
    MiscPoliciesCORO() {
      const link = document.createElement("a");
      link.href = "/assets/policy_letters/MiscPoliciesCORO.pdf";
      link.download = "MiscPoliciesCORO.pdf";
      link.click();
    },
    changeMegaMenuVisibility(val) {
      this.showMeagaMenu = val;
    },
    hideMegaMenu() {
      this.changeMegaMenuVisibility(false);
    },
    isActive(key) {
      return this.activeMainCategoryIndex === key;
    },

    changeMainCategory(index) {
      this.activeMainCategoryIndex = index;
      this.activeMainCategoryTitle = this.categoriesList[index].Name;
    },
  },
  computed: {
    ...mapGetters({ getUser: "appData/getUser", hasRole: "appData/hasRole" }),
  },
  watch: {
    activeMainCategoryIndex(_) {
      this.activeMainCategoryTitle =
        this.categoriesList[this.activeMainCategoryIndex].Name;
    },
  },
};
</script>

<style scoped>
.nav-link {
  color: black !important;
  font-weight: bold;
  font-size: 20px;
  font-family: "Montserrat", Helvetica, Arial, serif;
  margin-right: 15px;
  margin-bottom: 5px;
  padding-top: 10px;
}

/* Change the text color on hover */
.nav-link:hover {
  color: black !important;
}

/* Add a green border under the link when it's hovered */
.nav-link::after {
  content: "";
  display: block;
  height: 3px;
  background-color: green !important;
}
.dropdown-menu > .show {
  margin-left: 25px !important;
}
</style>
