<template>
  <section id="contact" class="">
    <b-container class="text-black">
      <div class=" ">
        <scrollactive>
          <b-row class="">
            <!-- <b-col lg="12" style="width: 100%; text-align: center">
              <h1
                class="font-weight-bolder align-text-center p-1 text-white"
                id="app-title"
                style="text-transform: uppercase"
              >
                <strong>{{ $t("contact.contactUs") }}</strong>
              </h1>
            </b-col> -->
            <b-col sm="12">
              <iframe
                id="mapIframe"
                width="90%"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d349.34019788862435!2d73.04763316155982!3d33.59225663443024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df95e44695baa1%3A0x167ab805d03e9045!2sVeterans%20Facilitation%20Center!5e0!3m2!1sen!2sus!4v1695365111312!5m2!1sen!2sus"
                title="Facilitation center"
                height="300"
                style="border: 0"
                allowfullscreen=""  
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                class="pt-2"
              ></iframe>
            </b-col>
          </b-row>
        </scrollactive>
        <!-- <hr  class="mt-5 mx-2" /> -->
      </div>

      <!-- <div class="py-2 text-center">
        <hr />
        <span
          class="brand-logo"
          style="display: inline-block; vertical-align: middle"
        >
          <b-img :src="this.appLogoImage" alt="logo" />
        </span>
        <h5
          class="text-center font-weight-bolder text-white"
          style="
            font-size: medium;
            display: inline-block;
            margin-left: 10px;
            vertical-align: middle;
          "
        >
          FACILITATION PORTAL
        </h5>
      </div> -->
    </b-container>
  </section>
</template>

<script>
import { $themeConfig } from "@themeConfig";
export default {
  data() {
    return {
      appName: "",
      appLogoImage: "",
      mapIframeSrc: "",
      mapIframeTitle: "Facilitation center",
    };
  },

  created() {
    this.appName = $themeConfig.app.appName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },

  mounted() {
    // this.initializeMap();
  },

  methods: {
    initializeMap() {
      const mapIframe = document.getElementById("mapIframe");

      if (mapIframe) {
        // Set the iframe source with the customized latitude and longitude
        const latitude = 33.592869103629816;
        const longitude = 73.04899946022573;
        mapIframe.src = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.4948460512874!2d${longitude}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df95b7d8e97e15%3A0xc409ecd55688a644!2sFacilitation%20center!5e0!3m2!1sen!2s!4v1678791693360!5m2!1sen!2s`;

        // Set the iframe title
        mapIframe.title = "Facilitation center";
      }
    },
  },
};
</script>
<style>
.my-bg {
  background-color: #094120 !important;
  opacity: 100%;

  background-image: radial-gradient(#216c28 0.75px, transparent 0.75px),
    radial-gradient(#094120 0.75px, #094120 0.75px);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}
b-input{
  color: black !important
}
</style>

<style scoped>
.my-link {
  display: block;
  padding: 4px !important;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  color: rgb(47, 42, 42) !important;
  padding: 0;
  transition: all 0.4s ease;
  position: relative;
}

.my-link.active,
.my-link:hover {
  color: #094120 !important;
  background-color: transparent !important;
}

.my-link.active:before,
.my-link:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}


</style>
