<template>
  <div class="p-0">
    <b-navbar
      toggleable="lg"
      type="dark"
      class="navbar-custom p-0 fixed-top"
      style="background-color: #aec2b6"
    >
      <b-navbar-brand to="/home" class="res d-flex d-flex-row">
        <img src="@/assets/images/logo/afp-logo.png" class="bgImg" alt="" />
        <h5
          class="text-black"
          style="
            font-size: 1.5rem;
            padding-top: 15px !important;
            font-weight: 600;
          "
        >
          {{ appName }}
        </h5>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class=""></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="mr-50">
        <b-navbar-nav class="ml-auto px-1">
          <b-nav-item to="navbar.home" class="py-50 d-block d-lg-none">
            <feather-icon icon="HomeIcon" size="16" class="mr-1 text-black" />
            <span class="text-black">Home</span>
          </b-nav-item>

          <b-nav-item href="#contact" class="py-50 d-block d-lg-none">
            <feather-icon
              icon="PhoneIcon"
              size="16"
              class="mr-1 text-black"
            /><span class="text-black">Contact Us</span>
          </b-nav-item>
          <li
            class="nav-item ml-lg-1 mt-1 mt-lg-0"
            v-if="(hasRole('su') || hasRole('op') || hasRole('op_admin')) || (this.getUser && this.getUser.role_data && this.getUser.role_data.code_name === 'bk__')"
          >
            <b-link
              class="nav-link font-weight-bolder d-block d-lg-none text-black"
              :to="{ name: 'NewDashboard' }"
            >
              <feather-icon icon="UserIcon" size="18" /> &nbsp;
              {{ $t("navbar.admin") }}
            </b-link>
          </li>
          <b-nav-item class="d-block d-lg-none">
            <b-nav-item-dropdown
              toggle-class="d-flex align-items-center "
              class="dropdown-user"
              style="list-style-type: none"
              :right="true"
            >
              <template #button-content>
                <div class="user-nav"></div>
                <a
                  class="nav-link scrollactive-item font-weight-bolder text-black"
                  style="margin-right: -10px !important"
                >
                  <feather-icon icon="BookIcon" size="18" />
                  {{ $t("navbar.PolicyLetters") }}</a
                >
              </template>

              <b-dropdown-item
                @click="hajjUmrah"
                link-class="d-flex align-items-center"
              >
                <span>{{ $t("navbar.Hajj_Umrah") }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="FCPS"
                link-class="d-flex align-items-center"
              >
                <span>{{ $t("navbar.FCPS") }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="MiscPoliciesCORO"
                link-class="d-flex align-items-center"
              >
                <span>{{ $t("navbar.Misc") }}</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-nav-item>
          <li
            class="nav-item ml-lg-1 mt-1 mt-lg-0"
            v-if="hasRole('fb' || 'fba')"
          >
            <b-link
              class="nav-link font-weight-bolder d-block d-lg-none"
              :to="{ name: 'FeedbackLanding' }"
            >
              <feather-icon icon="UserIcon" size="18" /> &nbsp;
              {{ $t("navbar.dash") }}
            </b-link>
          </li>
          <b-nav-item-dropdown
            v-if="getUser"
            toggle-class="d-flex align-items-center dropdown-user-link"
            class="dropdown-user d-block d-lg-none"
          >
            <template #button-content>
              <div class="d-sm-flex d-none user-nav">
                <p
                  class="user-name mt-4 font-weight-bolder"
                  style="color: #5e5873"
                >
                  {{ getUser ? getUser.name : "" }}
                </p>
                <p class="user-name">
                  {{ getUser ? getUser.role.name : "" }}
                </p>
              </div>
              <b-avatar
                size="40"
                variant="light-primary"
                badge
                :src="getUser ? getUser.profile_image : ''"
                class="badge-minimal"
                badge-variant="success"
              />
            </template>

            <b-dropdown-item
              v-if="hasRole('su') || hasRole('op') || hasRole('op_admin')"
              :to="{ name: 'Profile' }"
              link-class="d-flex align-items-center"
            >
              <feather-icon size="16" icon="UserIcon" class="mr-50" />
              <span>Profile</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              :to="{ name: 'Profile' }"
              link-class="d-flex align-items-center"
            >
              <feather-icon size="16" icon="UserIcon" class="mr-50" />
              <span>Profile</span>
            </b-dropdown-item>

            <b-dropdown-divider />

            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="logoutButtonClick"
            >
              <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
              <span>Logout</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <li v-else class="d-block d-lg-none">
            <b-nav-item :to="{ name: 'Login' }">
              <feather-icon icon="LogInIcon" size="15" class="mr-75" />
              {{ $t("navbar.signIn") }}
            </b-nav-item>
          </li>
          <b-nav-item href="tel:051 111 130 786" class="phone py-50 text-black">
            <feather-icon icon="PhoneIcon" size="16" class="mr-25 text-black" />
            <span class="text-black">
              {{ $t("navbar.helpline") }}:
              <span class="phone-number text-black">
                051-111-130-786</span
              ></span
            >
          </b-nav-item>
          <b-nav-item-dropdown
            id="dropdown-grouped"
            variant="link"
            class="dropdown-language py-50"
            right
            no-caret
          >
            <template #button-content>
              <span class="font-weight-bolder text-black">
                <feather-icon icon="TypeIcon" size="16" />
                &nbsp;Lang:
              </span>
              <span class="ml-50 font-weight-bolder text-black">{{
                currentLocale.name
              }}</span>
            </template>
            <b-dropdown-item
              v-for="localeObj in locales"
              :key="localeObj.locale"
              @click="$i18n.locale = localeObj.locale"
            >
              <span class="ml-50">{{ localeObj.name }}</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
export default {
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
  data() {
    return {
      appName: "",
      appFullName: "",
      appLogoImage: "",
      locales: [
        {
          locale: "en",
          img: require("@/assets/images/flags/en.png"),
          name: "English",
        },
        {
          locale: "ur",
          img: require("@/assets/images/flags/ur.png"),
          name: "اردو",
        },
      ],
      // navCollapsed: false,
      // bookingRoles: [
      //   'bk__agm',
      //   'bk__mess_sec',
      //   'bk__fmn_adm'
      // ]
    };
  },
  async mounted() {},
  computed: {
    ...mapGetters({ getUser: "appData/getUser", hasRole: "appData/hasRole" }),
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appFullName = $themeConfig.app.appFullName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  methods: {
    ...mapActions({ logout: "appData/logout" }),
    async logoutButtonClick() {
      try {
        const res = await this.logout();
        if (res.status === 204) {
          this.$router.push({ name: "Login" });
        }
      } catch (error) {
        error;
      }
    },
    hajjUmrah() {
      window.open("/assets/policy_letters/policyHajjUmra.pdf", "_blank");
    },
    FCPS() {
      window.open(
        "/assets/policy_letters/FCPS&MCPSTrgofCivTrainees.pdf",
        "_blank"
      );
    },
    MiscPoliciesCORO() {
      window.open("/assets/policy_letters/MiscPoliciesCORO.pdf", "_blank");
    },
  },
};
</script>

<style>
/* .navbar-custom {
  background-color:green;
  background-image: url("~@/assets/h-15.png");
  background-size: cover;
} */
.bgImg {
  height: 50px;
  margin: 5px;
  margin-left: 10px;
  margin-right: 5px;
  /* padding-top: 0;
  padding-left: 20px;
  padding-right: 20px; */
  /* width: 100% !important; */
}
/* @media (max-width: 990px) {
  .bgImg {
    height: 70px !important;
    margin: 0;
    padding-top: 0;
    margin-left: 0 !important;
    width: 100% !important;
  }
  .res {
    width: 70% !important;
    margin-right: 0 !important ;
    height: 70px !important;
  }
} */
.nav-link {
  /* color: white !important; */
  font-weight: bolder;
}
.nav-link.active,
.nav-link:hover {
  /* color: white !important; */
  background-color: transparent !important;
  font-weight: bolder;
}
.phone-number {
  direction: ltr;
  unicode-bidi: embed;
}
.text-black {
  color: black !important;
}
</style>
